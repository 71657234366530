import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@jsverse/transloco'

import { translocoScopeLoader } from '~core/utils'

export const fbAdAccountConfig: ApplicationConfig['providers'] = []

export const fbAdAccountI18nConfig: ApplicationConfig['providers'] = [
  // Provided via translationsConfig in app.config
  provideTranslocoScope({
    scope: 'facebookAdAccount',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
